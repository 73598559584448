import angular from "angular"
import { DigiLeanNgScope } from "@common/model/angularModel"
import { Asset, AssetRoleType, AssetUser, UserShortInfo } from "@api"

interface IMultiAssetSelectorScope extends DigiLeanNgScope {
    users: (UserShortInfo|AssetUser)[]
    selectedAssets: Asset[]
    //assets: Asset[]
    headline: any
    //add: (asset: Asset) => void
    //remove: (asset: Asset) => void
    ok: () => void
    cancel: () => void
}

const DigiLean = angular.module('DigiLean')
DigiLean.controller('assetMultiSelectorController', ['$scope', '$uibModalInstance', 'assets',
    function ($scope: IMultiAssetSelectorScope, $uibModalInstance, assets: Asset[]) {

        $scope.assets = assets
        function initialize() {
            if (Array.isArray($scope.assets)){
                $scope.selectedAssets = $scope.assets.filter(a => a.filteredAndInResultList === true)
            }
        }
        initialize()

        $scope.add = function (asset) {
            asset.filteredAndInResultList = true;
            $scope.selectedAssets.unshift(asset)
        }

        $scope.remove = function (asset) {
            const matchingAsset = $scope.selectedAssets.find(p => p.id == asset.id)
            if (matchingAsset) {
                const matchingAsset = $scope.assets.find(p => p.id == asset.id)
                if (matchingAsset) {
                    matchingAsset.filteredAndInResultList = false
                }
            }

            const matchingSelectedAsset = $scope.selectedAssets.find(p => p.id == asset.id)
            if (matchingSelectedAsset) {
                const i = $scope.selectedAssets.indexOf(matchingSelectedAsset)
                $scope.selectedAssets.splice(i, 1)
            }
        }

        $scope.clearFilter = function () {
            $scope.selectedAssets = [];
            $scope.assets.forEach(a => a.filteredAndInResultList = false)
        }

        $scope.ok = function () {
            $uibModalInstance.close($scope.assets)
        }


        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel')
        }
    }])