import * as assetService from "@common/services/assetService"

var DigiLean = angular.module('DigiLean');
DigiLean.controller('quickIncidentRegistrationConfigurationController', ['$scope', '$uibModalInstance','$translate','$filter','deviationService','dataListService','areaService','projectService','configuration',
    function ($scope, $uibModalInstance, $translate,$filter,deviationService,dataListService,areaService, projectService,configuration) {
        $scope.settings = configuration;

        $scope.severities = [
            {
                label: deviationService().getSeverityText("1"),
                severity: "1"
            },
            {
                label: deviationService().getSeverityText("2"),
                severity: "2"
            },
            {
                label: deviationService().getSeverityText("3"),
                severity: "3"
            },
            {
                label: deviationService().getSeverityText(""),
                severity: ""
            }
        ];

        function getDefaultConfig(){
            var deviationStandardElements = [
                {label: $translate.instant('DEVIATION_INCIDENT_DATE'),
                sourceColumn: "incidentDate",
                type: "date",
                isMandatory: true,
                show: false,
                value: new Date()
                },
                {label: $translate.instant('COMMON_TITLE'),
                sourceColumn: "title",
                type: "text",
                isMandatory: true,
                show: true
                },
                {label: $translate.instant('DASHBOARD_DESCRIPTION'),
                sourceColumn: "text",
                type: "textarea",
                isMandatory: false,
                show: false
                },
                {label: $translate.instant('COMMON_REPORTED_BY_GROUP'),
                sourceColumn: "assetId",
                type: "asset",
                isMandatory: true,
                show: true
                },
                {label: $translate.instant('COMMON_REPORTED_BY'),
                sourceColumn: "createdByUserId",
                type: "user",
                isMandatory: false, /*use logged in user as default*/
                show: true
                },
                {label: $translate.instant('DEVIATION_FOLLOW_UP_GROUP'),
                sourceColumn: "followUpAssetId",
                type: "followupasset",
                isMandatory: false, /*use reported by group as default*/
                show: true
                }    
            ];
            return deviationStandardElements;
        }

        function setConfigForRegistrationElement(element){
            if($scope.settings == null || $scope.settings == undefined || $scope.settings.registrationsetup == undefined) return;
            if(element && element.sourceColumn){
                const hasConfigValues = $scope.settings.registrationsetup.find(l => l.sourceColumn == element.sourceColumn)
                    if(hasConfigValues){
                        element.value = hasConfigValues.value;
                        element.show = hasConfigValues.show;                        
                        const hasFilterValues = $scope.filters.find(f => f.sourceColumn == element.sourceColumn);
                        if(hasFilterValues){
                            element.filter = [hasFilterValues];
                        }
                        setValueObjects(element);
                    }
            }
        }

        function setValueObjects(element){
            if(element.value){
                switch (element.type) {
                    case "asset":
                        $scope.selectedAsset = $scope.assets.find(asset => asset.id == element.value);
                        break;
                    case "followupasset":
                        $scope.selectedFollowupAsset = $scope.assets.find(asset => asset.id == element.value);
                        break;
                    case "AREA":
                        if($scope.areas){
                        $scope.selectedArea = $scope.areas.find(area => area.id == element.value);
                        } else {
                            areaService().getList().then(function (areas) {
                                $scope.areas = areas;
                                $scope.selectedArea = $scope.areas.find(area => area.id == element.value);
                            });
                        }
                        break;
                    case "PROJECT":
                        if($scope.projects && $scope.projects.length > 0){
                            $scope.selectedProject = $scope.projects.find(a => a.id == element.value);
                        } else {
                            projectService().getOngoingProjects().then(function (data) {
                                $scope.projects = data;
                                $scope.selectedProject = data.find(a => a.id == element.value);
                            });
                        }
                        break;
                    case "deviationseverity":
                        $scope.selectedSeverity = $scope.severities.find(s => s.severity == element.value);
                        break;
                    case "list":
                        element.selectedDataListItem = element.datalistelements.find(item => item.id === element.value);
                        break;
                }
            }
        }

        $scope.toggleShow = function (element) {
            if(element.show == true){
                element.value = null; // Reset default value since a list of options is no available
            }
        }

        $scope.selectAsset = function (asset, item) {
            $scope.selectedAsset = asset;
            item.value = asset.id;
        }
        $scope.selectFollowupAsset = function (asset, item) {
            $scope.selectedFollowupAsset = asset;
            item.value = asset.id;
        }
        $scope.selectArea = function (area, item) {
            $scope.selectedArea = area;
            item.value = area.id;
        }
        $scope.selectProject = function (project, item) {
            $scope.selectedProject = project;
            item.value = project.id;
        }
        $scope.selectSeverity = function (severity, item) {
            $scope.selectedSeverity = severity;
            item.value = severity.severity;
        }

        $scope.selectListItem = function (selectedDataListItem, item) {
            item.selectedDataListItem = selectedDataListItem;
            item.value = selectedDataListItem.id;
        }

        function setDefaultValues(){
            if (!$scope.settings?.registrationsetup) return; // No predefined settings to load
            if($scope.registrationsetup){
                for (var i = 0; i < $scope.registrationsetup.length; i++) {
                    setConfigForRegistrationElement($scope.registrationsetup[i]);
                }
            }
        }

        $scope.loadDeviationTypes = function(){
            if(!$scope.deviationTypes){      
                deviationService().getAllDeviationTypes().then(function(types) {
                    $scope.deviationTypes = types;
                    if(!$scope.deviationTypeId){
                        if($scope.deviationTypes.length > 0){
                            $scope.updateDeviationType($scope.deviationTypes[0]);
                        } else {
                            $scope.deviationTypeErrorMessage = $translate.instant('DEVIATION_NO_DEVIATION_TYPES');
                        }
                    } else {
                        var type = $filter('filter')($scope.deviationTypes, { id: $scope.deviationTypeId }, true)[0];
                        if(type){
                            $scope.updateDeviationType(type);
                        } 
                    }
                });
            } else {
                if(!$scope.deviationTypeId){
                    if($scope.deviationTypes.length > 0){
                        $scope.updateDeviationType($scope.deviationTypes[0]);
                    } else {
                        $scope.deviationTypeErrorMessage = $translate.instant('DEVIATION_NO_DEVIATION_TYPES');
                    }
                }
            }
        }

        $scope.updateDeviationType = function (type){
            $scope.registrationsetup = getDefaultConfig();
            $scope.deviationTypeId = type.id;
            $scope.deviationTypeTitle = type.title;
            getDeviationTypeConfiguration();
        };

        function init() {
            if ($scope.settings) {
                if($scope.settings.deviationTypeId){
                    $scope.deviationTypeId = $scope.settings.deviationTypeId;
                    $scope.filters = $scope.settings.filters;
                }   
            }
            if(!$scope.filters) {
                $scope.filters = [];
            }
            $scope.loadDeviationTypes();
            
            assetService.getAllAssets(true).then(function (data) {
                $scope.assets = data;
            });
        }
        init();

        function loadProjects() {
            if(!$scope.projects || $scope.projects.length == 0){
                projectService().getOngoingProjects().then(function (projects) {
                    $scope.projects = projects;
                });
            }
        }
        function loadAreas() {
            if(!$scope.areas || $scope.areas.length == 0){
                areaService().getList().then(function (areas) {
                    $scope.areas = areas;
                });
            }
        }

        function setCustomList(field){
            if(!field || field.dataListId == null || field.dataListId == undefined) return;
            var listOptions = {includeDeletedItems:false};
            dataListService().getItems(field.dataListId, listOptions).then(function (list) {
                field.datalistelements = list;
                field.sourceColumn = "customField" + field.deviationCustomFieldId;        
                setConfigForRegistrationElement(field);
                $scope.registrationsetup.splice(field.sortOrder, 0, field);
            });
        }

        function checkFields(validFields) {
            if (validFields) {
                var nextSortOrder = $scope.registrationsetup.length
                for (var f = 0; f < validFields.length; f++) {
                    validFields[f].sortOrder = nextSortOrder;
                    nextSortOrder++;
                    switch (validFields[f].type) {
                        case "PROJECT":
                            validFields[f].sourceColumn = "projectId";
                            $scope.registrationsetup.push(validFields[f]);
                            loadProjects();
                            break;
                        case "AREA":
                            validFields[f].sourceColumn = "areaId";
                            $scope.registrationsetup.push(validFields[f]);
                            loadAreas();
                            break;
                        case "DEVIATION_INCIDENT_TIME":
                            $scope.showTime = true;
                            break;
                        case "list":
                            setCustomList(validFields[f]);                        
                            break;
                        default:
                            if(validFields[f].deviationCustomFieldId)  {
                                validFields[f].sourceColumn = "customField" + validFields[f].deviationCustomFieldId;
                            }
                            $scope.registrationsetup.splice(validFields[f].sortOrder, 0, validFields[f]);
                            break;
                    }
                }
            }
        }

        function getDeviationTypeConfiguration(){
            if(!$scope.deviationTypeId) return;
            deviationService().getDeviationTypeConfiguration($scope.deviationTypeId).then(function (configuration) {
                $scope.typeconfiguration = configuration;
                checkFields($scope.typeconfiguration.fields);
                $scope.categories = $scope.typeconfiguration.categories;
                $scope.consequences = $scope.typeconfiguration.consequences;
                $scope.problemReasons = $scope.typeconfiguration.causes;
                $scope.registrationsetup.push(
                    {label: $translate.instant('COMMON_CATEGORY'),
                    sourceColumn: "problemCategoryId",
                    type: "problemcategory",
                    isMandatory: true,
                    show: true
                    });
                if($scope.consequences && $scope.consequences.length >0){
                    $scope.registrationsetup.push(
                        {label: $translate.instant('DEVIATION_CONSEQUENCE'),
                        sourceColumn: "lossTypeId",
                        type: "consequence",
                        isMandatory: false,
                        show: false
                        });
                }
                $scope.registrationsetup.push(
                    {label: $translate.instant('DEVIATION_SEVERITY'),
                    sourceColumn: "severity",
                    type: "deviationseverity",
                    isMandatory: false,
                    show: true,
                    value: ""
                    });
                if($scope.problemReasons && $scope.problemReasons.length >0){
                    $scope.registrationsetup.push(
                        {label: $translate.instant('DEVIATION_CAUSE'),
                        sourceColumn: "problemReasonId",
                        type: "problemreason",
                        isMandatory: false,
                        show: false
                        });
                } 
                setDefaultValues();             
            });
        }

        function isInputOk(){
            $scope.targetMissing = false;
            if($scope.registrationsetup){
                for (var i = 0; i < $scope.registrationsetup.length; i++) {
                    const hasFilterValues = $scope.filters.find(l => l.sourceColumn == $scope.registrationsetup[i].sourceColumn)
                    if($scope.registrationsetup[i].isMandatory){
                        if(!$scope.registrationsetup[i].show && !hasFilterValues && !$scope.registrationsetup[i].value){
                            $scope.targetMissing = true;
                            $scope.registrationsetup[i].dataMissing = true;
                        } else {
                            $scope.registrationsetup[i].dataMissing = false;
                        }
                    }
                }
            }
            if(!$scope.deviationTypeId || $scope.targetMissing){
                return false;
            } else {
                return true;
            }
        }

        function isCustomField(sourceColumn) {
            if (sourceColumn.length >= 12) {
                if (sourceColumn.substring(0,11) == "customField") return true;
            }
        }

        function getCustomFieldId(sourceColumn) {
            return sourceColumn.substring(12, (sourceColumn.length + 1))
        }

        $scope.updateFilter = function (updatedSourceColumn, filters) {
            var hasFilter = $filter('filter')($scope.filters, { sourceColumn: updatedSourceColumn });
            // Remove existing filter connected to sourceColumn
            if (hasFilter && hasFilter.length > 0) {
                // Remove existing filter
                angular.forEach(hasFilter, function (filter, key) {
                    var index = $scope.filters.indexOf(hasFilter[key]);
                    $scope.filters.splice(index, 1);
                });
            }
            angular.forEach(filters, function (filter) {
                if (filter) {
                    if($scope.filters === undefined){
                        $scope.filters = [];
                    }
                     // Custom field check
                    if (isCustomField(updatedSourceColumn)) {
                        var customFieldId = getCustomFieldId(updatedSourceColumn);
                        filter.customFieldId = customFieldId;
                    }
                    $scope.filters.push(filter);
                }
            });
        };

        function setCategoryDefaultValues(){
            const categoryregistrationsetup = $scope.registrationsetup.find(l => l.sourceColumn == "problemCategoryId");
            if(categoryregistrationsetup){
                if(!categoryregistrationsetup.show){ //if category is not shown then all filtered categories are default values. Note that deviation supports multiple categories
                    categoryregistrationsetup.value = $scope.filters.find(l => l.sourceColumn == "problemCategoryId").items;             
                }
            }
        }

        function setConsequenceDefaultValues(){
            const consequenceregistrationsetup = $scope.registrationsetup.find(l => l.sourceColumn == "lossTypeId");
            if(consequenceregistrationsetup){
                if(!consequenceregistrationsetup.show){ //if consequence/losstype is not shown then all filtered values are default values. Note that deviation supports multiple consequences
                    const hasDefaultValues = $scope.filters.find(l => l.sourceColumn == "lossTypeId");
                    if(hasDefaultValues){
                        consequenceregistrationsetup.value = hasDefaultValues.items;         
                    }    
                }
            }
        }

        function setProblemReasoneDefaultValues(){
            const problemreasonregistrationsetup = $scope.registrationsetup.find(l => l.sourceColumn == "problemReasonId");
            if(problemreasonregistrationsetup){
                if(!problemreasonregistrationsetup.show){ //if problemreason is not shown then all filtered values are default values. Note that deviation supports multiple values
                    const hasDefaultValues = $scope.filters.find(l => l.sourceColumn == "problemReasonId");
                    if(hasDefaultValues){
                        problemreasonregistrationsetup.value = hasDefaultValues.items;         
                    }    
                }
            }
        }

        $scope.ok = function () {
            if(isInputOk()){
                if(!$scope.settings){
                    $scope.settings = {};
                }
                if($scope.deviationTypeId){
                    setCategoryDefaultValues();
                    setConsequenceDefaultValues();
                    setProblemReasoneDefaultValues();
                    $scope.settings.deviationTypeId = $scope.deviationTypeId;
                    $scope.settings.registrationsetup = $scope.registrationsetup;
                } else {
                    $scope.settings.deviationTypeId = null;
                }
                $scope.settings.filters = $scope.filters;
    
                $uibModalInstance.close($scope.settings);
            }
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

    }]);